<template>

</template>

<script>
export default {
  name: "iamLogin",
  mounted() {
    localStorage.removeItem('token'); //localStorage uuctoken信息
    this.$store.commit('user/SET_TOKEN','');
    // 在页面加载完成后执行跳转操作
    window.location.href = process.env.VUE_APP_IAM_LOGIN_API + '/?project=haier-rrs-login-page&sync-third-token=false&client_id=' + process.env.VUE_APP_IAM_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_GMS_URL + '&exit_url=' + process.env.VUE_APP_GMS_URL + '&sync-third-token=false#exit';
  }
}

</script>

<style scoped>

</style>
